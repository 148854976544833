import React, { forwardRef, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { motion } from "framer-motion";

const BoxComponent = forwardRef((props, ref) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    props.scrollToComponent();
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      color="gray"
    >
      <motion.div
        ref={ref}
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{
          scale: [0.5, isClicked ? 1 : 1.2, 1],
          opacity: [0, 1, 1],
          y: [100, -50, 0],
        }}
        transition={{ duration: 1 }}
        style={{
          width: "80%",
          maxWidth: 600,
          height: "80%",
          maxHeight: 600,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "black",
          borderRadius: 10,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          {props.title}
        </Typography>
        <video
          src={props.video}
          autoPlay
          loop
          muted
          playsInline
          style={{ width: "100%", height: "auto" }}
        />
        <Button variant="contained" onClick={handleClick} color="secondary">
          Next
        </Button>
      </motion.div>
    </Box>
  );
});

export default BoxComponent;
