import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Box from "./Box.js";
import Hero from "./Hero.js";
import "./css/style.css";

const components = [
  { id: "Choose your genre", video: "/steps/choosegenre.mp4", counter: 0 },
  { id: "Or prompt your own", video: "/steps/clubbanger.mp4", counter: 1 },
  {
    id: "Change the Key and BPM to match your needs",
    video: "/steps/keybpmchange.mp4",
    counter: 2,
  },
  {
    id: "Change the volume or sounds for different stems",
    video: "/steps/stemvolume.mp4",
    counter: 3,
  },
];

function App() {
  const [activeComponent, setActiveComponent] = useState(-1);

  useEffect(() => {
    const componentElements = document.querySelectorAll(".component");
    componentElements.forEach((element) => {
      if (!element.classList.contains("active")) {
        element.classList.add("blur");
      }
    });
  }, []);

  const scrollToComponent = () => {
    const nextComponent = components.find(
      (component) => component.counter === activeComponent + 1
    );
    if (nextComponent) {
      const element = document.getElementById(nextComponent.id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        setActiveComponent(nextComponent.counter);
        // Remove blur class from active component and add it to other components
        const componentElements = document.querySelectorAll(".component");
        componentElements.forEach((element) => {
          if (element.id === nextComponent.id) {
            element.classList.remove("blur");
          } else {
            element.classList.add("blur");
          }
        });
      }
    }
  };

  return (
    <>
      <div className="bg-black">
        <Hero scrollToComponent={scrollToComponent} />
        {components.map((componentData) => (
          <div
            id={componentData.id}
            key={componentData.id}
            className={`component ${
              activeComponent === componentData.counter ? "active" : "blur"
            }`}
          >
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              style={{ position: "relative" }}
            >
              <Box
                title={componentData.id}
                scrollToComponent={scrollToComponent}
                video={componentData.video}
              />
            </motion.div>
          </div>
        ))}
      </div>
    </>
  );
}

export default App;
