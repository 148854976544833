import React from "react";
import { motion } from "framer-motion";

export default function Hero({ scrollToComponent }) {
  const handleClick = (option) => {
    console.log(option);
    if (option === "Product Flow") {
      scrollToComponent();
    } else if (option === "Let’s Play") {
      window.open("https://playground.auroraa.me", "_self");

      // Handle demo functionality
    } else if (option === "Pitch Deck") {
      console.log("pitch deck");
      // Handle pitch deck functionality (external link)
      window.open("https://trxaudio.my.canva.site/trx", "_blank");
    } else if (option === "Team") {
      window.open("https://www.youtube.com/watch?v=9sygIDWVtZI", "_self");
    }
  };

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Wrapper for the blurred content */}
        <motion.div
          className={`relative `}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.6 }}
        >
          <div className="absolute left-0 bottom-0 -ml-20 hidden lg:block pointer-events-none">
            <svg
              className="max-w-full"
              width="564"
              height="552"
              viewBox="0 0 564 552"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* ... */}
            </svg>
          </div>

          {/* Hero content */}
          <div className="relative pt-32 pb-10 md:pt-40 md:pb-16">
            {/* Section header */}
            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
              <motion.h1
                className="h1 mb-4  text-white"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 0.6 }}
              >
                TRX <i>Demo</i>
              </motion.h1>

              <motion.p
                className="text-xl text-gray-400 mb-8"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 0.6 }}
              >
                Welcome to TRX's Product Flow, we want you to get an idea of how
                an artist would get to use our platform.
              </motion.p>
              <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4, duration: 0.6 }}
                  className="mb-4"
                >
                  <a
                    className="btn text-white bg-purple-600 hover:bg-purple-700 w-full sm:w-auto"
                    href="#0"
                    onClick={() => handleClick("Product Flow")}
                  >
                    Product Flow
                  </a>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4, duration: 0.6 }}
                  className="mb-4"
                >
                  <a
                    className="btn text-white bg-purple-600 hover:bg-purple-700 w-full sm:w-auto"
                    href="#0"
                    onClick={() => handleClick("Let’s Play")}
                  >
                    Playground
                  </a>
                  <a
                    className="btn text-white bg-purple-600 hover:bg-purple-700 w-full sm:w-auto"
                    href="#0"
                    onClick={() => handleClick("Team")}
                  >
                    Team Introduction
                  </a>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4, duration: 0.6 }}
                  className="mb-4"
                >
                  <a
                    className="btn text-white bg-purple-600 hover:bg-purple-700 w-full sm:w-auto"
                    href="#0"
                    onClick={() => handleClick("Pitch Deck")}
                  >
                    Pitch Deck
                  </a>
                </motion.div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Modal or next component */}
        {/* {isModalVisible && (
          <motion.div
            className="fixed top-0 left-0 right-0 bottom-0 bg-white z-50"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
          </motion.div>
        )} */}
      </div>
    </section>
  );
}
